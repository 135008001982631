import gql from "graphql-tag";
import { get } from "lodash";

import { build } from "../../lib/graphql";

export const GET_LAYOUT_DATA = gql`
  {
    me @client {
      id
      email
      name {
        first
        formatted
      }
      profile {
        avatar
        company {
          name
        }
      }
    }
    community @client {
      appName
      familiarName
      icon
      navMore {
        label
        link
      }
      products {
        forum {
          enabled
        }
      }
    }
    ads @client {
      id
      imageUrl
      link
      shape
    }
  }
`;

export const appQuery = build.query(
  { query: GET_LAYOUT_DATA, returnPartialData: true },
  ({ data, loading }, originalProps) => {
    // Attribute the link with the user's email for Hubspot tracking

    return {
      loading,
      appName: get(data, "community.appName"),
      appIcon: get(data, "community.icon"),
      user: {
        id: get(data, "me.id"),
        avatar: get(data, "me.profile.avatar"),
        displayName: get(data, "me.name.formatted"),
        company: get(data, "me.profile.company.name"),
        community: get(data, "community.familiarName"),
        location: "",
      },
      hasForum: get(data, "community.products.forum.enabled", false),
      moreMenuItems: get(data, "community.navMore"),
      ...originalProps,
    };
  }
);
