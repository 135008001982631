import "./style.css";

import gql from "graphql-tag";
import { t as localized } from "localizify";
import React, { useCallback, useReducer, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import sanitizeHtml from "sanitize-html";
import {
  Container,
  Grid,
  Icon,
  Loader,
  Message,
  Responsive,
  Sidebar,
} from "semantic-ui-react";

import ButtonTertiary from "../../../components/button/tertiary/index.web";
import { namespacedT } from "../../../lib/i18n";
import wordCount from "../../../lib/wordCount";
import prefixUrl from "../../../public-path";
import {
  ARTICLE_SCAN_CREATE,
  useSubmitArticleForReview,
} from "../graphql/article";
import { linkTo } from "../routes-links";
import BylineEditor from "./byline-editor.web";
import DateTime from "./date-time.web";
import Editor from "./editor.web";
import Previewer from "./previewer.web";
import SidebarContent from "./sidebar-content.web";
import Skeleton from "./skeleton.web";
import ValidationRequirement from "./validation-requirement.web";

const startHolidayMessage = new Date("2024-12-23");
const endHolidayMessage = new Date("2025-01-02");
const now = new Date();
const showHolidayMessage = now > startHolidayMessage && now < endHolidayMessage;
const holidayMessage = `COMMUNITY is closed from 12/24 through 1/1 to give our team some well-deserved time off. During that week, we are not allowing new article submissions (or resubmits). You may return to your dashboard on 1/2 to submit your article. Thank you for being a valued member!`;

const t = namespacedT("articles");

const ARTICLE_FRAGMENT = gql`
  fragment Article_Fragment on Article {
    id
    status
    updatedAt
    currentRevision {
      id
      title
      content
      updatedAt
    }
    editor {
      id
    }
  }
`;

const ARTICLE_IN_FLIGHT = gql`
  query articleInFlight {
    articleInFlight {
      ...Article_Fragment
    }
  }
  ${ARTICLE_FRAGMENT}
`;

const ARTICLE_CREATE = gql`
  mutation articleCreate {
    articleCreate {
      ...Article_Fragment
    }
  }
  ${ARTICLE_FRAGMENT}
`;

const ARTICLE_SAVE_REVISION = gql`
  mutation articleAutoSaveRevision($input: ArticleRevisionInput) {
    articleAutoSaveRevision(input: $input) {
      ...Article_Fragment
    }
  }
  ${ARTICLE_FRAGMENT}
`;

const initialArticleState = {
  title: "",
  content: "",
  titleValid: false,
  contentValid: false,
};

function htmlStrippedWordCount(string) {
  const htmlStrippedString = sanitizeHtml(string, {
    allowedTags: [],
    allowedAttributes: {},
  });
  return wordCount(htmlStrippedString);
}

function isTitleValid(title) {
  // The title field in the db is varchar(255) and there are apparently people that
  // try to paste their entire article into the title field which won't work.
  return !!title && title.length > 0 && title.length < 255;
}

function isContentValid(content) {
  const count = htmlStrippedWordCount(content);
  return count >= 500 && count <= 1000;
}

function reducer(state, action) {
  switch (action.type) {
    case "updateTitle":
      return {
        ...state,
        title: action.title,
        titleValid: isTitleValid(action.title),
      };
    case "updateContent":
      return {
        ...state,
        content: action.content,
        contentValid: isContentValid(action.content),
      };
    default:
      return state;
  }
}

const editStatuses = ["DRAFT", "NEEDS_REVISION"];

const previewStatuses = ["READY_TO_APPROVE", "FIRST_ROUND_EDIT", "FINAL_EDIT"];

export default function InterimEditor() {
  const [articleState, articleDispatch] = useReducer(
    reducer,
    initialArticleState
  );
  const [hasSavedByline, setHasSavedByline] = useState(false);
  const {
    submitArticleForReview,
    error: submitError,
  } = useSubmitArticleForReview();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [scanArticle, { error: scanError }] = useMutation(ARTICLE_SCAN_CREATE);
  const [submittingForReview, setSubmittingForReview] = useState(false);

  const { data, loading } = useQuery(ARTICLE_IN_FLIGHT, {
    onCompleted: onCompletedData => {
      const { currentRevision } = onCompletedData.articleInFlight
        ? onCompletedData.articleInFlight
        : {};
      const title =
        currentRevision && currentRevision.title ? currentRevision.title : "";
      const content =
        currentRevision && currentRevision.content
          ? currentRevision.content
          : "";

      // Set initial data once loaded
      articleDispatch({ type: "updateTitle", title });
      articleDispatch({
        type: "updateContent",
        content,
      });
    },
  });

  const [create, { error: createError, loading: createLoading }] = useMutation(
    ARTICLE_CREATE,
    {
      update(cache, { data: { articleCreate } }) {
        cache.writeQuery({
          query: ARTICLE_IN_FLIGHT,
          data: {
            articleInFlight: {
              ...articleCreate,
            },
          },
        });
      },
    }
  );

  const [saveRevision, { error: saveError, loading: saving }] = useMutation(
    ARTICLE_SAVE_REVISION
  );

  const articleInFlight =
    data && data.articleInFlight ? data.articleInFlight : {};
  const articleStatus = articleInFlight.status
    ? articleInFlight.status
    : "DRAFT";
  const canSave =
    (articleInFlight.id ||
      articleState.title.length > 0 ||
      articleState.content.length > 0) &&
    editStatuses.includes(articleStatus);
  const canSubmit =
    articleState.titleValid &&
    articleState.contentValid &&
    hasSavedByline &&
    !(showHolidayMessage && editStatuses.includes(articleStatus));

  const handleSaveRevision = useCallback(
    async (title, content) => {
      try {
        let articleId = articleInFlight.id;

        if (!articleInFlight.id) {
          const {
            data: { articleCreate },
          } = await create();
          articleId = articleCreate.id;
        }

        return saveRevision({
          variables: {
            input: {
              articleId,
              title,
              content,
            },
          },
        });
      } catch (err) {
        // Errors will be displayed via the error prop returned by the useMutation hook
        return null;
      }
    },
    [articleInFlight.id, create, saveRevision]
  );

  const onHasUnread = useCallback(() => {
    if (window.innerWidth < Responsive.onlyComputer.minWidth) {
      setMobileSidebarOpen(true);
    }
  }, []);

  if (loading) {
    return <Skeleton />;
  }

  if (![...editStatuses, ...previewStatuses].includes(articleStatus)) {
    // Prevent redirect before article scan has finsihed when submitting for review
    if (!submittingForReview) {
      window.location.href = prefixUrl(linkTo.articles());
    }
  }

  const hasError = !!(createError || saveError || submitError || scanError);

  return (
    <Sidebar.Pushable>
      <Sidebar
        animation="overlay"
        visible={mobileSidebarOpen}
        direction="right"
        className="ie-mobile-sidebar"
      >
        <div className="ie-mobile-sidebar-top">
          <ButtonTertiary
            className="ie-mobile-sidebar-close-button"
            onClick={() => setMobileSidebarOpen(false)}
          >
            <Icon className="angle-right" size="big" />
          </ButtonTertiary>
        </div>

        <div className="ie-mobile-sidebar-content">
          <SidebarContent
            articleId={articleInFlight.id}
            status={articleStatus}
            hasEditorAssigned={
              articleInFlight.editor && !!articleInFlight.editor.id
            }
            contentGuidelinesInitiallyExpanded={articleStatus === "DRAFT"}
            onHasUnread={onHasUnread}
          />
        </div>
      </Sidebar>

      <Sidebar.Pusher dimmed={mobileSidebarOpen}>
        <Container className="ie-container">
          <div className="ie-top">
            {canSave ? (
              <ButtonTertiary
                className="ie-save-exit"
                onClick={async () => {
                  await handleSaveRevision(
                    articleState.title,
                    articleState.content
                  );
                  window.location.href = prefixUrl(linkTo.articles());
                }}
              >
                <Icon className="angle-left" size="big" /> Save article &amp;
                exit editor
              </ButtonTertiary>
            ) : (
              <ButtonTertiary
                as="a"
                href={prefixUrl(linkTo.articles())}
                className="ie-save-exit"
              >
                <Icon className="angle-left" size="big" /> Back
              </ButtonTertiary>
            )}

            <ButtonTertiary
              onClick={() => setMobileSidebarOpen(true)}
              className="ie-mobile-sidebar-open-button"
            >
              <Icon className="info-circle" size="large" />
            </ButtonTertiary>
          </div>

          <Grid columns={2} stackable>
            <Grid.Column computer={11} tablet={16}>
              {editStatuses.includes(articleStatus) && (
                <>
                  {hasError && (
                    <Message negative className="ie-error-message">
                      <Message.Header>
                        An error occurred while{" "}
                        {submitError || scanError ? "submitting" : "saving"}{" "}
                        your article.
                      </Message.Header>
                      <p>Please try again.</p>
                    </Message>
                  )}
                  {showHolidayMessage && (
                    <div
                      className="ui blue icon info message"
                      style={{ color: "white", backgroundColor: "#2185d0" }}
                    >
                      <i aria-hidden="true" className="info-circle icon" />
                      <div className="content">
                        <p>
                          {holidayMessage.replace(
                            /COMMUNITY/g,
                            localized("app.name")
                          )}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="ie-main-section">
                    <div className="ie-editor-top-meta">
                      <Grid>
                        <Grid.Column width={8}>
                          <div
                            className={`ie-status-label ${
                              articleStatus === "NEEDS_REVISION"
                                ? "red"
                                : "blue"
                            }`}
                          >
                            <Icon className="info-circle" size="small" />{" "}
                            {t(`status_messages.${articleStatus}.title`)}
                          </div>
                        </Grid.Column>

                        <Grid.Column width={8} textAlign="right">
                          {saving || createLoading ? (
                            <span className="ie-saving-label">
                              <Loader
                                active
                                size="tiny"
                                inline
                                style={{ margin: "0 3px 2px 0" }}
                              />{" "}
                              Saving...
                            </span>
                          ) : (
                            <>
                              {articleInFlight.currentRevision && (
                                <span className="ie-saving-label">
                                  <Icon className="save" size="small" />{" "}
                                  Autosaved{" "}
                                  <DateTime
                                    date={
                                      articleInFlight.currentRevision.updatedAt
                                    }
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Grid.Column>
                      </Grid>
                    </div>

                    <Editor
                      articleId={articleInFlight.id}
                      articleState={articleState}
                      articleDispatch={articleDispatch}
                      handleSaveRevision={handleSaveRevision}
                      canSubmit={canSubmit}
                      submitArticleForReview={submitArticleForReview}
                      scanArticle={scanArticle}
                      submittingForReview={submittingForReview}
                      setSubmittingForReview={setSubmittingForReview}
                      submitError={submitError}
                    />

                    <div className="ie-editor-bottom-meta">
                      <ValidationRequirement
                        isValid={articleState.titleValid}
                        label="Suggested Title"
                      />
                      <ValidationRequirement
                        isValid={articleState.contentValid}
                        label={`${htmlStrippedWordCount(
                          articleState.content
                        )}/1000 words (500 min)`}
                      />
                      <ValidationRequirement
                        isValid={hasSavedByline}
                        label="Byline added"
                      />
                    </div>

                    <BylineEditor
                      hasSavedByline={hasSavedByline}
                      setHasSavedByline={setHasSavedByline}
                    />
                  </div>
                </>
              )}

              {previewStatuses.includes(articleStatus) && (
                <Previewer
                  articleId={articleInFlight.id}
                  articleState={articleState}
                  status={articleStatus}
                />
              )}
            </Grid.Column>

            <Grid.Column computer={5} tablet={16}>
              <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                <SidebarContent
                  articleId={articleInFlight.id}
                  status={articleStatus}
                  hasEditorAssigned={
                    articleInFlight.editor && !!articleInFlight.editor.id
                  }
                  contentGuidelinesInitiallyExpanded={articleStatus === "DRAFT"}
                />
              </Responsive>
            </Grid.Column>
          </Grid>
        </Container>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}
